import React from "react"
import { useStoreState } from "easy-peasy"
import { Typography, Box, Button } from "@lib/ui/core"
import Container from "@layouts/Container"
import Layout from "@layouts/MainLayout"

const ResourcesPage = () => {
  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  return (
    <Layout bgcolor="#14a1e9" homepage={!accessToken}>
      <Box display="flex" width="100%">
        <Container>
          <Box bgcolor="#ffffff" my={2} p={2} borderRadius="32px">
            <Box fontSize="32px" fontWeight="700" align="center">
              資源下載
            </Box>
            <Box my={5} width="100%" display="flex" justifyContent="center">
              <Box>
                <Box fontSize="24px" mb={1} align="center">
                  Formula List
                </Box>
                <a
                  href="https://cdn.mathod.info/docs/Appendix.pdf"
                  style={{
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>
                    <Typography color="textSecondary">
                      點擊檢視或下載
                    </Typography>
                  </Button>
                </a>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default ResourcesPage
